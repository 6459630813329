.border-pattern {
  position: center;
  height: 730px;
  width: 850px;
  margin: auto;
  background-color: #d6d6e4;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../assests/img/bg.png");
  background-size: 100% 100%;
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  .border-pattern {
    height: 810px;
  }
}

@media screen and (max-width: 575px) {
  .border-pattern {
    height: 980px;
  }
}

@media screen and (max-width: 486px) {
  .border-pattern {
    height: 1040px;
  }
  #qrcode {
    height: 54px;
    width: 54px;
  }
}

@media screen and (max-width: 415px) {
  .border-pattern {
    height: 1050px !important;
  }
  .logo-row {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 371px) {
  .border-pattern {
    height: 1150px !important;
  }
}

.key-border {
  width: 100%;
  min-height: 180px;
  max-width: 190px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../assests/img/border-key.4.png");
}

#canvas {
  margin-top: 13px;
}
content {
  position: absolute;
  left: 10mm;
  top: 10mm;
  height: 178mm;
  width: 245mm;
}

.logo-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 5mm;
  margin-bottom: 3mm;
}

.design-row {
  width: 160px;
  height: auto;
  margin: 0 auto;
  margin-top: 1mm;
  margin-bottom: 2mm;
}

#logo {
  width: 17%;
  text-align: center;
}

#design {
  width: 100%;
  height: 100%;
  text-align: center;
}
.inner-content {
  margin: 4mm;
  padding: 10mm;
  height: 148mm;
  text-align: center;
  background-image: url("../assests/img/logowatermark_new_4.png");
  background-repeat: no-repeat;
  background-size: 1180px 820px;

  background-position-x: center;
  background-position-y: center;
}

h1 {
  text-transform: uppercase;
  font-family: "Cambria";
  color: rgb(33, 30, 73);
  margin-bottom: 0;
}

h2 {
  font-size: 24pt;
  margin-top: 0;
  padding-bottom: 1mm;
  display: inline-block;
  border-bottom: 1mm solid #991b1b;
}

h2::after {
  content: "";
  display: block;
  padding-bottom: 4mm;
  border-bottom: 1mm solid #991b1b;
}

h3 {
  font-size: 20pt;
  font-family: sans-serif;
  margin-bottom: 0;
  margin-top: 10mm;
}

#cert-to {
  font-size: 11pt;
}

#cert-holder {
  font-size: 25pt;
  border-bottom: 4px solid #211e49;
  display: inline-block;
  color: rgb(33, 30, 73);
  margin-right: 8px;
}

#cert-course {
  font-size: 15pt;
}

#cert-issuer {
  font-size: 12pt;
}
.cert-dates {
  margin-top: 10mm;
  font-size: 12pt;
  line-height: 0.7;
}

.cert-day {
  font-size: 15pt;
  line-height: 0.7;
  color: rgb(33, 30, 73);
}

#cert-bot {
  margin-bottom: 2mm;
  font-size: 9pt;
  margin-top: 10px !important;
}

#qrcode {
  margin-top: 9mm;
  width: fit-content;
  height: 68px;
  width: 68px;
}

#btngrp {
  position: center;
  font-size: 18px;
  margin-bottom: 10mm;
}
.container {
  position: relative;
}

.top-btns {
  width: 850px !important;
  max-width: 100%;
}

.icon-btn {
  font-size: 24px !important;
  margin-left: 10px !important;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
}

.icon-btn:hover {
  opacity: 0.9;
  transform: scale(1.08);
}

.linkedin-logo {
  font-size: 25px;
}

.background {
  position: absolute;
  z-index: 0;
  width: calc(100vw - (30 * 100vw / 100));
  height: calc(100vh - (0 * 100vh / 100));
  background-image: url("../assests/img/logowatermark_new_4.png");
  background-repeat: no-repeat;
  /*  background-color: rgba(232, 231, 246, 0.5); */
  display: block;
  color: gray;
  left: calc(100vh - (90 * 100vh / 100));
  top: calc(100vh - (90 * 100vh / 100));
}

.bg-text {
  display: none;
  font-size: 1020%;
  background: transparent;
  font-style: normal;
  opacity: 0.4;
  transform: rotate(300deg);
  -webkit-transform: rotate(310deg);
}

@media screen and (max-width: 728px) {
  .background {
    height: 1040px;
  }
  #qrcode {
    height: 54px;
    width: 54px;
  }

  .inner-content {
    margin: 4mm;
    padding: 10mm;
    height: 148mm;
    text-align: center;
    background-image: url("../assests/img/logowatermark_new_4.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    background-position-y: 58%;
  }
}

@media screen and (max-width: 486px) {
  .background {
    height: 1040px;
  }
  #qrcode {
    height: 54px;
    width: 54px;
  }

  .inner-content {
    margin: 4mm;
    padding: 10mm;
    height: 148mm;
    text-align: center;
    background-image: url("../assests/img/logowatermark_new_4.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    background-position-y: 60%;
  }
}

@media screen and (max-width: 415px) {
  .background {
    height: 1050px !important;
  }
  .key-border {
    background-image: none !important;
    min-height: 100px !important;
  }
  .logo-row {
    margin-top: 40px !important;
  }

  .inner-content {
    margin: 4mm;
    padding: 10mm;
    height: 148mm;
    text-align: center;
    background-image: url("../assests/img/logowatermark_new_4.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    background-position-y: 61%;
  }
}

@media screen and (max-width: 280px) {
  .background {
    height: 1150px !important;
  }

  .inner-content {
    margin: 4mm;
    padding: 10mm;
    height: 148mm;
    text-align: center;
    background-image: url("../assests/img/logowatermark_new_4.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: center;
    background-position-y: 69%;
  }
}

@media print {
  body,
  body * {
    visibility: hidden;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .inner-content {
    background-image: url("../assests/img/logowatermark_new_4.png");
    background-repeat: repeat;
    background-size: 650px 470px;
    background-position-x: 30px;
  }

  .container {
    margin: 0 !important;
    padding: 0 30px !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .border-pattern * {
    visibility: visible;
  }

  .border-pattern {
    visibility: visible;
    height: 750px !important;
  }

  .bg-text {
    font-size: 1020%;
    background: transparent;
    font-style: normal;
    opacity: 0.4;
    transform: rotate(350deg);
    -webkit-transform: rotate(350deg);
  }

  .background {
    position: absolute;
    z-index: 0;
    width: 850px;
    background-image: url("../assests/img/logowatermark_new_4.png");
    display: block;
    color: gray;
    background-position-x: -30px;
    left: calc(100vh - (80 * 100vh / 100));
    top: calc(100vh - (70 * 100vh / 100));
  }
}

@page {
  size: A4 portrait !important;
  margin: 0 !important;
}

@import "bootstrap/";

.loading {
  position: fixed;
  z-index: 99999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.home-profile-image-edit {
  position: absolute;
  top: 58px;
  width: 20px;
  height: 20px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.card-main {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  color: black !important;
}

.react-tel-input {
  .form-control {
    display: block !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    padding-left: 3.25rem !important;
  }
}

.shrink-text-card {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.wrap-long-text {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.border-md-start {
  @include media-breakpoint-up(md) {
    border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
}

.border-sm-top {
  @include media-breakpoint-only(xs) {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
  @include media-breakpoint-only(sm) {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
}

.modal-header {
  color: black !important;
}

.share-modal {
  .modal-header .close {
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    font-size: 2rem !important;
    margin-top: -10px !important;
  }
}

.share-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.share-btn {
  margin: 0 6px !important;
}

.topbar-link {
  color: #466ad8 !important;
  user-select: none !important;
  transition: 0.15s all ease;
  border-radius: 4px;
  padding: 5px 10px !important;
}

.link-active {
  color: #183794 !important;
}

.topbar-link:hover {
  background-color: #f5f6f8;
}

.navbar-toggler {
  background-image: none !important;
  color: transparent !important;
}

.player-wrapper {
  position: relative;
  height: 100vh;
  background-color: #111;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.profile-name {
  text-transform: capitalize;
}

.profile-page {
  p {
    color: rgb(41, 40, 40);
  }
}

.profile-about {
  text-align: justify;
  line-height: 1.6;
}

.profile-image {
  max-height: 250px !important;
  min-height: 250px !important;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: 0% !important;
  aspect-ratio: 1 !important;
}

.profile-info {
  min-height: 220px !important;
}

@media screen and (max-width: 767px) {
  .profile-row-1 {
    flex-direction: column-reverse !important;
  }
}

.profile-phygital-key {
  width: 100%;
  min-height: 190px;
  max-width: 190px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../assests/img/border-key.4.png");
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  #canvas-small {
    margin: 14px auto;
    position: absolute;
    height: 70px !important;
    width: 70px !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent !important;
  }
}

.profile-phygital-key-big {
  width: 100%;
  min-height: 450px;
  max-width: 450px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../assests/img/border-key.4.png");
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  #canvas-big {
    height: 165px !important;
    width: 165px !important;
    right: 0;
    background-color: transparent !important;
  }
}

.profile-other-img {
  max-height: 160px !important;
  max-width: 160px !important;

  @media screen and (max-width: 767px) {
    max-height: 180px !important;
    max-width: 180px !important;
  }
}

.profile-btns {
  background-color: #577ff7 !important;
  outline: none !important;
  border: none !important;
}

.logo {
    float: right;
    top: 20px;
    position: absolute;
}

.menu-btn {
  background-color: #fff; 
  color: #466ad8; 
  width: 45;
  display: flex;
  font-size: 26;
  height: 45;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
}

@media only screen and (max-width: 1000px) {
  .logo {
    float: right;
    top: 25px;
    position: absolute;
    left: 80px;
  }
}